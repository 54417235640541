const IMG_CDN = 'https://portapros.imgix.net';

/* ==================================================
Copyright year:

Updates copyright year with JS
===================================================*/
var currentYear  = new Date().getFullYear();
$('#copyright').text(currentYear);


/* ==================================================
Back to top link:

Smooth scrolls back to top of page
===================================================*/

$('.back-to-top').click(function(){
  $('html, body').animate({scrollTop : 0},600);
  return false;
});

$('.smooth-scroll').click(function(event) {
    var target = $(this.hash);
    event.preventDefault();
    $('html, body').animate({
      scrollTop: target.offset().top
    }, 600, function() {
      // Callback after animation
      // Must change focus!
      var $target = $(target);
      $target.focus();
      if ($target.is(":focus")) { // Checking if the target was focused
        return false;
      } else {
        $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
        $target.focus(); // Set focus again
      };
    });
  });


/* ==================================================
Magnific:

Magnific is used for lightbox style popups.

.image-link is for displaying larger versions of
images from a a link.

.video-link is for displaying videos from a link.
Common providers like Youtube and Vimeo will
stop playing when window is closed.
===================================================*/

$('.image-link').magnificPopup({ type: 'image' });
$('.video-link').magnificPopup({ type: 'iframe' });
$('.bio-trigger').magnificPopup({
  type:'inline',
  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});

/* ==================================================
Toilet calculator slider:

Toggles between the different toilet calulators
when the button is clicked
===================================================*/
var $portaprosCalcWrapper = $('.portapros-calculators');

$('.portapros-calculators .calculator-toggle button').click(function(e) {
  e.preventDefault();
  if($(this).hasClass('active')) {
    return
  } else {
    $('.portapros-calculators .calculator-toggle button').removeClass('active');
    $(this).addClass('active');
    $portaprosCalcWrapper.toggleClass('events-shown construction-shown')
  }
});


/* ==================================================
Sliders:

Glide setup and initialization
===================================================*/
if(document.querySelector('.glide')) {
  let sliders = document.querySelectorAll('.glide');

  if(sliders.length > 1) {
    sliders.forEach(slide => {
      let glideMedia = new Glide(slide, {
        type: 'carousel',
        perView: 1,
        perTouch: 1
      }).mount();
    })
  } else {
    var glideMedia = new Glide('.glide', {
        type: 'carousel',
        perView: 1,
        perTouch: 1
      }).mount();
  }
}

/* ==================================================
Rent now button:

Takes user direct to checkout
===================================================*/
$(".buy-now-triggers").click(function(e){
   e.preventDefault();
   window.history.pushState("Sent to checkout", "Checkout", window.location.pathname + "#/checkout");
   // window.location = window.location.pathname + "/#/checkout";
});


/* ==================================================
Hero swap
===================================================*/
let heroVideoPlay = document.querySelector('button.play-button');
let heroVideo = document.querySelector('.hero-video-full');

if(heroVideoPlay) {
  heroVideoPlay.addEventListener('click', function(e) {
    let iframe = heroVideo.querySelector('iframe');
    iframe.src = iframe.dataset.src;
    let player = new Vimeo.Player(iframe);
    heroVideo.classList.remove('hide');
    heroVideo.classList.add('visible');
    player.play();
  })
}


/**
 * A lightweight youtube embed. Still should feel the same to the user, just MUCH faster to initialize and paint.
 *
 * Thx to these as the inspiration
 *   https://storage.googleapis.com/amp-vs-non-amp/youtube-lazy.html
 *   https://autoplay-youtube-player.glitch.me/
 *
 * Once built it, I also found these:
 *   https://github.com/ampproject/amphtml/blob/master/extensions/amp-youtube (👍👍)
 *   https://github.com/Daugilas/lazyYT
 *   https://github.com/vb/lazyframe
 */
class LiteYTEmbed extends HTMLElement {
    connectedCallback() {
        this.videoId = this.getAttribute('videoid');

        let playBtnEl = this.querySelector('.lty-playbtn');
        // A label for the button takes priority over a [playlabel] attribute on the custom-element
        this.playLabel = (playBtnEl && playBtnEl.textContent.trim()) || this.getAttribute('playlabel') || 'Play';

        /**
         * Lo, the youtube placeholder image!  (aka the thumbnail, poster image, etc)
         *
         * See https://github.com/paulirish/lite-youtube-embed/blob/master/youtube-thumbnail-urls.md
         *
         * TODO: Do the sddefault->hqdefault fallback
         *       - When doing this, apply referrerpolicy (https://github.com/ampproject/amphtml/pull/3940)
         * TODO: Consider using webp if supported, falling back to jpg
         */
        if (!this.style.backgroundImage) {
          this.posterUrl = `https://i.ytimg.com/vi/${this.videoId}/hqdefault.jpg`;
          // Warm the connection for the poster image
          LiteYTEmbed.addPrefetch('preload', this.posterUrl, 'image');

          this.style.backgroundImage = `url("${this.posterUrl}")`;
        }

        // Set up play button, and its visually hidden label
        if (!playBtnEl) {
            playBtnEl = document.createElement('button');
            playBtnEl.type = 'button';
            playBtnEl.classList.add('lty-playbtn');
            this.append(playBtnEl);
        }
        if (!playBtnEl.textContent) {
            const playBtnLabelEl = document.createElement('span');
            playBtnLabelEl.className = 'lyt-visually-hidden';
            playBtnLabelEl.textContent = this.playLabel;
            playBtnEl.append(playBtnLabelEl);
        }

        // On hover (or tap), warm up the TCP connections we're (likely) about to use.
        this.addEventListener('pointerover', LiteYTEmbed.warmConnections, {once: true});

        // Once the user clicks, add the real iframe and drop our play button
        // TODO: In the future we could be like amp-youtube and silently swap in the iframe during idle time
        //   We'd want to only do this for in-viewport or near-viewport ones: https://github.com/ampproject/amphtml/pull/5003
        this.addEventListener('click', e => this.addIframe());
    }

    // // TODO: Support the the user changing the [videoid] attribute
    // attributeChangedCallback() {
    // }

    /**
     * Add a <link rel={preload | preconnect} ...> to the head
     */
    static addPrefetch(kind, url, as) {
        const linkEl = document.createElement('link');
        linkEl.rel = kind;
        linkEl.href = url;
        if (as) {
            linkEl.as = as;
        }
        document.head.append(linkEl);
    }

    /**
     * Begin pre-connecting to warm up the iframe load
     * Since the embed's network requests load within its iframe,
     *   preload/prefetch'ing them outside the iframe will only cause double-downloads.
     * So, the best we can do is warm up a few connections to origins that are in the critical path.
     *
     * Maybe `<link rel=preload as=document>` would work, but it's unsupported: http://crbug.com/593267
     * But TBH, I don't think it'll happen soon with Site Isolation and split caches adding serious complexity.
     */
    static warmConnections() {
        if (LiteYTEmbed.preconnected) return;

        // The iframe document and most of its subresources come right off youtube.com
        LiteYTEmbed.addPrefetch('preconnect', 'https://www.youtube-nocookie.com');
        // The botguard script is fetched off from google.com
        LiteYTEmbed.addPrefetch('preconnect', 'https://www.google.com');

        // Not certain if these ad related domains are in the critical path. Could verify with domain-specific throttling.
        LiteYTEmbed.addPrefetch('preconnect', 'https://googleads.g.doubleclick.net');
        LiteYTEmbed.addPrefetch('preconnect', 'https://static.doubleclick.net');

        LiteYTEmbed.preconnected = true;
    }

    addIframe() {
        const params = new URLSearchParams(this.getAttribute('params') || []);
        params.append('autoplay', '1');

        const iframeEl = document.createElement('iframe');
        iframeEl.width = 560;
        iframeEl.height = 315;
        // No encoding necessary as [title] is safe. https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#:~:text=Safe%20HTML%20Attributes%20include
        iframeEl.title = this.playLabel;
        iframeEl.allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';
        iframeEl.allowFullscreen = true;
        // AFAIK, the encoding here isn't necessary for XSS, but we'll do it only because this is a URL
        // https://stackoverflow.com/q/64959723/89484
        iframeEl.src = `https://www.youtube-nocookie.com/embed/${encodeURIComponent(this.videoId)}?${params.toString()}`;
        this.append(iframeEl);

        this.classList.add('lyt-activated');

        // Set focus for a11y
        this.querySelector('iframe').focus();
    }
}
// Register custom element
customElements.define('lite-youtube', LiteYTEmbed);


// Hubspot Form Submissions
let hsForms = document.querySelectorAll('.hs-form');
hsForms.forEach(form => {
  form.addEventListener('submit', handleHsFormSubmit);
  let hubspotToken = getCookie('hubspotutk');
  if(hubspotToken) {
    form.elements['hutk'].value = hubspotToken;
  }
});

function handleHsFormSubmit(e) {
  e.preventDefault();

  if(!recaptchaLoaded) {
    reCaptchaOnFocus(e, e.target.id);
  }
  if(!Alpine.store('recaptcha').response) {
    Alpine.store('recaptcha').showCaptchaError = true;
    return;
  }
  const formHandler = '/.netlify/functions/hubspot_forms';
  const formData = new FormData(e.target);
  let productInterests = formData.getAll('product_interest');

  const formJSON = Object.fromEntries(formData.entries());
  let formSubmit = e.target.querySelector('[type="submit"]');
  let formLoader = e.target.querySelector('.loading-dots').classList.remove('hide');
  formSubmit.disabled = true;

  if(productInterests.length) {
    formJSON.product_interest = productInterests.join(';');
  }

  fetch(formHandler, {
    method: "POST",
    body: JSON.stringify(formJSON)
  }).then(res => {
    if (res.status != 200) { throw new Error("Bad Server Response"); }
    if(e.target.dataset.gtmEventName) {
      window.dataLayer.push({ event: e.target.dataset.gtmEventName });
    }
    let noRedirect = e.target.action.split('/').pop();
    if(noRedirect === 'none') {
      document.querySelector(`#${e.target.id} + .thanks-message`).classList.remove('hide')
      e.target.remove();
    } else {
      window.location = e.target.action;
    }
    return res.text();
  })
  // (E) HANDLE ERRORS - OPTIONAL
  .catch(err => {
    console.error(err);
    if (err.name === "AbortError") return;
    throw new Error("Error with form submission", { cause: err });
  });
}

/**
 * Get the value of a cookie
 * Source: https://gist.github.com/wpsmith/6cf23551dd140fb72ae7
 * @param  {String} name  The name of the cookie
 * @return {String}       The cookie value
 */
function getCookie (name) {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// Recaptcha setup
let recaptchaNeeded = document.querySelectorAll('.g-recaptcha');
let recaptchaLoaded = false;

if(recaptchaNeeded) {
  recaptchaNeeded.forEach(form => {
    let recaptchaForm = form.closest('form');
    document.querySelectorAll(`#${recaptchaForm.id} [required]`).forEach(el => el.addEventListener('focus', reCaptchaOnFocus, false))
  })
}

function reCaptchaOnFocus(e, formId) {
  let formID = '';
  if(formId) {
    formID = formId;
  } else {
    formID = e.target.form.id;
  }
  let head = document.getElementsByTagName('head')[0]
  let script = document.createElement('script')
  script.type = 'text/javascript';
  script.src = 'https://www.google.com/recaptcha/api.js'
  head.appendChild(script);
  recaptchaLoaded = true;
  document.querySelectorAll(`#${formID} [required]`).forEach(el => el.removeEventListener('focus', reCaptchaOnFocus))
}

// Alpine
function handleRecaptcha(response) {
  Alpine.store('recaptcha').handleRecaptcha(response);
}

document.addEventListener("alpine:init", () => {

  Alpine.store('recaptcha', {
    response: '',
    showCaptchaError: false,
    validRecaptcha: false,
    handleRecaptcha(response) {
      if(response) {
        this.response = response;
        this.showCaptchaError = false;
        this.validRecaptcha = true;
        document.querySelectorAll('.g-recaptcha-response').forEach(recaptchaField => recaptchaField.value = response);
        return response;
      }

      this.response = '';
      this.showCaptchaError = true;
      this.validRecaptcha = false;
    }
  });

  Alpine.store('cartToggle', {
    getCartCount() {
      Snipcart.store.subscribe(() => {
        this.count = Snipcart.store.getState().cart.items.count;
      });
    },

    open() {
      Snipcart.api.theme.cart.open();
    },
    count: 0
  });

  Alpine.data(
    "thumbnailGallery",
    (imagePath, imageAlt, imageWidth) => ({
      init() {
        if(imagePath) {
          this.currentImage = imagePath;
          this.alt = imageAlt;
          this.width = imageWidth;
        }
      },
      selectImage(imagePath, imageAlt, width, height) {
        if(imagePath === this.currentImage) {
          return;
        }
        this.loading = true;
        let loaded = document.querySelector('#main-product-image');

        loaded.addEventListener("load", () => {
          this.loading = false;
        }, {once: true });

        if(width) {
          this.width = width
        } else {
          this.width = '656';
        }
        if(height) {
          this.height = height
        } else {
          this.height = '656';
        }
        this.currentImage = imagePath;
        this.alt = imageAlt;
      },
      currentImageSrc() {
        return {
          src: `${IMG_CDN}${this.currentImage}?auto=format&width=${this.width}`,
          srcset: `${IMG_CDN}${this.currentImage}?auto=format&width=${this.width}&dpr=2&q=40 2x`,
        }
      },
      currentImage: "",
      alt: "",
      height: "",
      width: "",
      loading: false,
    })
  );

  Alpine.data("addToCart", (quantity, maxQuantity, productId) => ({
    init() {
      this.quantity = quantity || 1;
      this.maxQuantity = maxQuantity || 4;
      Alpine.store('selectedAddon').value = productId;
    },

    maxQuantityExceeded() {
      return this.quantity > this.maxQuantity;
    },

    quantity: '',
    maxQuantity: ''
  }));

  Alpine.store('selectedAddon', {
    value: ''
  });

  Alpine.data("rentalButtons", () => ({
    init() {
      this.$nextTick(() => {
        this.select(document.querySelectorAll('.product-select-button')[0]);
      })
    },

    select(el) {
      Alpine.store('selectedAddon').value = el.dataset.value;
      this.selectedId = el.id;
    },
    isSelected(id) {
      return this.selectedId === id
    },
    whichChild(el, parent) {
        return Array.from(parent.children).indexOf(el) + 1
    },

    selectedId: null
  }));

  Alpine.bind('RentalButton', () => ({
    type: 'button',
    '@click'() {
      return this.select(this.$el)
    },
    ':aria-pressed'() {
      return this.isSelected(this.$el.id)
    },
    ':class'() {
      return this.isSelected(this.$el.id) ? 'active' : ''
    },
    ':id'() {
      return this.$id('variant', this.whichChild(this.$el.parentElement, this.$refs.rentalbuttons))
    }
  }));

  // Tabs
  Alpine.data('tabs', (showFirstActive = true) => ({
    selectedId: null,
    init() {
        // Set the first available tab on the page on page load.
      if(showFirstActive) {
        this.$nextTick(() => this.select(this.$id('tab', 1)))
      }
    },
    select(id) {
      this.selectedId = id
    },
    isSelected(id) {
        return this.selectedId === id
    },
    whichChild(el, parent) {
        return Array.from(parent.children).indexOf(el) + 1
    }
  }));

  Alpine.bind('TabButton', () => ({
    type: 'button',

    '@click'() {
      return this.select(this.$el.id)
    },

    ':aria-selected'() {
      return this.isSelected(this.$el.id)
    },

    ':class'() {
      return this.isSelected(this.$el.id) ? 'active' : ''
    },

    ':id'() {
      return this.$id('tab', this.whichChild(this.$el.parentElement, this.$refs.tablist))
    },

    ':tabindex'() {
      return this.isSelected(this.$el.id) ? 0 : -1
    }

  }));

  Alpine.bind('TabList', () => ({
    '@keydown.right.prevent.stop'() {
      return this.$focus.wrap().next()
    },

    '@keydown.home.prevent.stop'() {
      return this.$focus.first()
    },

    '@keydown.pageup.prevent.stop'() {
      return this.$focus.first()
    },

    '@keydown.left.prevent.stop'() {
      return this.$focus.wrap().prev()
    },

    '@keydown.end.prevent.stop'() {
      return this.$focus.last()
    },

    '@keydown.page-down.prevent.stop'() {
      return this.$focus.last()
    },
  }));

  Alpine.bind('TabPanel', () => ({
    'x-show'() {
      return this.isSelected(this.$id('tab', this.whichChild(this.$el, this.$el.parentElement)))
    },

    ':aria-labelledby'() {
      return this.$id('tab', this.whichChild(this.$el, this.$el.parentElement))
    },

  }));

  // Accordions
  Alpine.data('accordion', () => ({
    expanded: false
  }))

  Alpine.bind('AccordionContent', () =>({
    'x-collapse': '',
    'x-show': 'expanded'

  }));

  Alpine.bind('AccordionTrigger', () =>({
    '@click': 'expanded = !expanded',
  }));


  Alpine.data("filterCards", () => ({
    filterByTag(tag) {
      this.currentTag = tag;
    },
    includesCurrentTag(tag) {
      if(tag.includes(';')) {
        let tags = tag.split(';');
        return tags.includes(this.currentTag);
      }
      return tag === this.currentTag;
    },
    currentTag: ''
  }));

  Alpine.data("filterYear", (defaultYear) => ({
    init() {
      this.currentYear = defaultYear;
    },
    filterByYear(year) {
      this.currentYear = year;
    },
    includesCurrentYear(year) {
      return year === this.currentYear;
    },
    currentYear: ''
  }));

  // Quote request selector
  Alpine.data('quoteRequestFormSelector', () => ({
    init() {
      const url = new URL(window.location);
      const formName = url.searchParams.get('form');
      if(formName) {
        this.selectForm(formName)
      }
    },
    selectedForm: '',
    formSelected: false,

    goBack() {
      this.formSelected = false;
      this.selectedForm = '';
      this.deleteFormParam();
    },

    selectForm(form) {
      this.formSelected = true;
      this.selectedForm = form;
      this.updateFormParam(form);
    },

    deleteFormParam() {
      const url = new URL(window.location);
      url.searchParams.delete('form');
      history.pushState({}, "", url);
    },

    updateFormParam(form) {
      const url = new URL(window.location);
      url.searchParams.set('form', form);
      history.pushState({}, "", url);
    }

  }));

  // Service area checker
  Alpine.data('serviceAreaCheck', (serviceAreaList) => ({
    init() {
      this.serviceAreaList = serviceAreaList;
      Alpine.store('serviceAreaCheck').serviceAreaList = serviceAreaList;
    },
    placeSelected: false,
    inServiceArea: false,
    noServiceArea: false,
    noResults: false,
    serviceAreaList: [],
    currentLocation: '',
    currentLocationServiceOffice: '',
    officeLocationLinks: {
      'Nampa': {
        link: '/locations/nampa-idaho/',
        name: 'Nampa, ID'
      },
      'Ontario': {
        link: '/locations/ontario-oregon/',
        name: 'Ontario, OR'
      },
      'Pocatello': {
        link: '/locations/pocatello-idaho/',
        name: 'Pocatello, ID'
      }
    },

    isInServiceArea() {
      if(!Alpine.store('serviceAreaCheck').placeSelected) { return }
      window.dataLayer = window.dataLayer || [];
      // Reset flags in case new place is selected
      this.currentLocation = '';
      this.inServiceArea = false;
      this.noServiceArea = false;
      this.noResults = false;

      let city = Alpine.store('serviceAreaCheck').city;
      let state = Alpine.store('serviceAreaCheck').state;
      if(!city || !state) {
        // If address can't be translated to City and State e.g. Twin Falls Grade
        this.noResults = true;
        window.dataLayer.push({ event: 'delivery-search-address-error' });
        return
      }

      if(city.long_name && state.long_name) {
          let filteredServiceArea = this.serviceAreaList.filter(item => item.city === city.long_name);

          if(filteredServiceArea.length && filteredServiceArea[0].state === state.short_name) {
            // See if city and state match item in our list
            this.inServiceArea = true;
            this.currentLocation = `${city.long_name}, ${state.short_name}`;
            this.currentLocationServiceOffice = `<a href="${this.officeLocationLinks[filteredServiceArea[0].office].link}" target="_blank">${this.officeLocationLinks[filteredServiceArea[0].office].name}</a>`;
            window.dataLayer.push({ event: 'delivery-search-success' });
            return this.currentLocation;
          }

          // City and State are out of service area so send data to Airtable
          window.dataLayer.push({ event: 'delivery-search-out-of-area' });
          this.noServiceArea = true;
          fetch("/.netlify/functions/no_service_area", {
            method: "POST",
            body: `${city.long_name}, ${state.short_name}`
          }).then(res => {
            if (res.status != 200) { throw new Error('Bad Server Response'); }
            return res.text();
          })
          this.currentLocation = `${city.long_name}, ${state.short_name}`;
          return this.currentLocation;
      }
    }
  }))

  Alpine.store('serviceAreaCheck', {
    serviceAreaList: [],
    city: '',
    state: ''
  });

  // Sidenav
  Alpine.store('sideNav', {
    open: false,

    close() {
      this.open = false;
    },

    toggle() {
      this.open = !this.open;
      let bodyWrapper = document.querySelector('body');
      if(this.open) {
        bodyWrapper.classList.add('offscreen-nav-visible');
        return;
      }
      bodyWrapper.classList.remove('offscreen-nav-visible');
    }
  });

  Alpine.data('sideNavDropdown', (id= '') => ({
    get expanded() {
      return this.active === id;
    },
    set expanded(value) {
      this.active = value ? id : null
    }
  }));

  const isDesktop = window.matchMedia('(min-width: 1100px)');

  const handleResize = e => {
    if (e.matches) {
      Alpine.store('sideNav').open = false;
      document.body.classList.remove('offscreen-nav-visible');
    }
  };

  isDesktop.addEventListener('change', e => handleResize(e));

  handleResize(isDesktop);

  // Analytics data
  function formatItems (items) {
    return items.map(function (item, index) {
      return {
        item_name: item.name,
        item_id: item.id,
        price: `${item.totalPriceWithoutDiscountsAndTaxes}`,
        quantity: `${item.quantity}`,
        index: index+1
      };
    });
  }

  function createDiscountString(items) {
    let discount = 0;
    items.forEach(item => discount += item.amount);
    return discount;
  }

  document.addEventListener('snipcart.ready', function() {
    Alpine.store('cartToggle').getCartCount();

    // Analytics data
    Snipcart.events.on('item.added', function(item) {
      itemAdded(item);
    });

    Snipcart.events.on('item.removed', function(item) {
      itemRemoved(item);
    });

    Snipcart.events.on('cart.confirmed', function(order) {
      orderCompleted(order);
    });

    Snipcart.events.on('shipping.selected', function() {
      cartOpened();
    });
  });

  function orderCompleted(order) {
    const obj = {
      event: 'purchase',
      ecommerce: {
        transaction_id: order.token,
        affiliation: 'Website',
        value: order.total,
        tax: order.taxesTotal,
        shipping: order.shippingDetails.cost,
        currency: order.currency,
        discount: createDiscountString(order.discounts.items),
        items: formatItems(order.items.items)
      }
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(obj);
  }

  function cartOpened() {
    const obj = {
      event: 'begin_checkout',
      ecommerce: {
        items: formatItems(Snipcart.store.getState().cart.items.items)
      }
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(obj);
  }

  function itemAdded(item){
    const obj = {
      event: 'add_to_cart',
      ecommerce: {
        items: formatItems([item])
      }
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(obj);
  }

  function itemRemoved(item){
    const obj = {
      event: 'remove_from_cart',
      ecommerce: {
        items: formatItems([item])
      }
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(obj);
  }

  Alpine.data("contentSelector", (id='') => ({
    init() {
      this.currentId = id;
    },
    showSelected(id) {
      this.currentId = id;
    },
    currentId: ""
  }));

});

// Callback for Google Maps libraries for service area checker
function initServiceAreaChecker() {
    // const center = { lat: 50.064192, lng: -130.605469 };
    // // Create a bounding box with sides ~10km away from the center point
    const defaultBounds = {
      north: 48.994761,
      east: -111.027307,
      south: 42.001886,
      west: -121.146033,
    };
    const input = document.getElementById("pac-input");
    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: "us" },
      types: ["address"],
      fields: ["address_components", "name"],
      strictBounds: true,
    };
    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener('place_changed', function() {
        let place = autocomplete.getPlace(); // Get the selected place details
        Alpine.store('serviceAreaCheck').placeSelected = true;
        Alpine.store('serviceAreaCheck').city = place.address_components.find(address => address.types.includes('locality'))
        Alpine.store('serviceAreaCheck').state = place.address_components.find(address => address.types.includes('administrative_area_level_1'))
    });
}